<script lang="ts">
  import type {
    RelatedResource,
    Resource,
    ResourceType,
  } from '$lib/contentful/models/resource';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import Icon from '../icon/icon.svelte';
  import Text from '../text/text.svelte';
  import ResourceLink from './resource-link.svelte';
  import ResourceTag from './resource-tag.svelte';

  export let resource: Resource | RelatedResource;

  $: ({
    type,
    platform,
    customerLogo,
    title,
    industry,
    sdk,
    entityId,
    cardImage,
    presenters,
  } = resource);

  const isReplayVideo = (type: ResourceType): boolean => {
    return ['Replay 2022', 'Replay 2023', 'Replay 2024'].includes(type);
  };

  // maintain a 16:9 ratio
  const imgWidth = 16 * 30;
  const imgHeight = 9 * 30;
</script>

<div
  class="resource-card"
  style="--img-width:{imgWidth}px;--img-height:{imgHeight}px;"
>
  <div
    class="image-container"
    class:cloud={platform === 'Cloud' && !cardImage}
    class:oss={platform === 'Self-Hosted' && !cardImage}
    class:is-logo={!!customerLogo}
  >
    <div class="tag">
      <ResourceTag fieldId="type" {entityId} {type}>{type}</ResourceTag>
    </div>
    <ResourceLink {resource}>
      {#if customerLogo}
        <ContentfulImage
          fieldId="customerLogo"
          {entityId}
          src={customerLogo.url}
          alt={customerLogo.description}
          height={80}
          fitStrategy="pad"
        />
      {:else if cardImage}
        <ContentfulImage
          fieldId="cardImage"
          {entityId}
          src={cardImage.url}
          alt={cardImage.description}
          width={imgWidth}
          height={imgHeight}
          fitStrategy="fill"
        />
      {/if}
    </ResourceLink>
  </div>
  <div class="content">
    <div>
      {#if isReplayVideo(type) && presenters.length > 0}
        <div class="presenters">
          {#each presenters.slice(0, 3) as presenter}
            <p class="font-mono text-sm">{presenter.name}</p>
          {/each}
        </div>
      {/if}
      {#if industry || sdk}
        <div class="categories">
          {#if industry}
            <Text fieldId="industry" {entityId} variant="mono-16" as="p"
              >{industry}</Text
            >
          {/if}
          {#if sdk}
            <Text fieldId="sdk" {entityId} variant="mono-16" as="p">{sdk}</Text>
          {/if}
        </div>
      {/if}
      <ResourceLink {resource}>
        <Text fieldId="title" {entityId} variant="heading-32" as="p"
          >{title}</Text
        >
      </ResourceLink>
    </div>

    <ResourceLink class="flex flex-row gap-4 py-4 pr-6" {resource}>
      View {isReplayVideo(type) ? 'Talk' : type}
      <Icon name="arrow-right" />
    </ResourceLink>
  </div>
</div>

<style lang="postcss">
  .resource-card {
    @apply relative bg-deep-blue border border-graphite rounded-2xl flex flex-col h-full max-w-[var(--img-width)];
  }

  .image-container {
    @apply rounded-t-2xl overflow-hidden aspect-[16/9] max-h-[var(--img-height)];

    &.is-logo {
      @apply flex flex-col items-center justify-center px-4;
    }

    &.cloud {
      @apply bg-cover bg-center bg-no-repeat bg-[url('/images/backgrounds/cloud-resource-bg.jpg')];

      @supports (
        background-image: url('/images/backgrounds/cloud-resource-bg.avif')
      ) {
        background-image: url('/images/backgrounds/cloud-resource-bg.avif');
      }
    }

    &.oss {
      @apply bg-cover bg-center bg-no-repeat bg-[url('/images/backgrounds/self-hosted-resource-bg.jpg')];

      @supports (
        background-image:
          url('/images/backgrounds/self-hosted-resource-bg.avif')
      ) {
        background-image: url('/images/backgrounds/self-hosted-resource-bg.avif');
      }
    }
  }

  .tag {
    @apply flex flex-row items-start absolute top-4 left-4;
  }

  .content {
    @apply flex grow flex-col justify-between px-6 pt-6 gap-6;
  }

  .categories {
    @apply flex flex-row gap-8 text-blue-gray-500 uppercase mb-4;
  }

  .presenters {
    @apply flex flex-wrap gap-4 text-blue-gray-500 mb-4;
  }
</style>
